import './App.css';
import { Link } from 'react-router-dom';
import Banner from './banner.png';

function NavigationBar(params) {
    var currentPath = params.currentPath;

    return (
        <nav>
            <div class="navContainer" >
                <input id="nav-toggle" type="checkbox" />
                <Link to="/" onClick={(e) => {
                        document.getElementById("nav-toggle").checked = false;
                    }}><div class="logo"><img class="main-logo" src={Banner} /></div></Link>
                <ul class="links" 
                    onClick={(e) => {
                        document.getElementById("nav-toggle").checked = false;
                    }}>
                    <li>
                        {currentPath === '/' || currentPath === '/home' ?
                        <Link to="/home" style={{background:'#1890ff'}}>Home</Link>
                        :
                        <Link to="/home">Home</Link>}
                    </li>
                    <li>
                        {currentPath === '/services' ?
                        <Link to="/services" style={{background:'#1890ff'}}>Services</Link>
                        :
                        <Link to="/services">Services</Link>}
                    </li>
                    <li>
                        {currentPath === '/about' ?
                        <Link to="/about" style={{background:'#1890ff'}}>About</Link>
                        :
                        <Link to="/about">About</Link>}
                    </li>
                    <li>
                        {currentPath === '/contact' ?
                        <Link to="/contact" style={{background:'#1890ff'}}>Contact</Link>
                        :
                        <Link to="/contact">Contact</Link>}
                        </li>
                </ul>
                <label for="nav-toggle" class="icon-burger">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </label>
            </div>
        </nav>
    );
}

export default NavigationBar;