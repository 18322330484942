import './App.css';
import { Link } from 'react-router-dom';
import InstagramIcon from './instagram.svg';
import FacebookIcon from './facebook.svg';
import LocationIcon from './map-pin-w.svg';

function Footer() {

    return (
        <footer>
          <div class="footerContainer">
            <div class="footerDiv" style={{verticalAlign:'middle'}}>
                <h2 style={{ padding: 0, margin: 0, color: '#fff' }}>
                    <img src={LocationIcon} width={20}  /> Location</h2>
                <div style={{ margin: '0', }}>
                    249 Cartesian Gate<br />
                    Winnipeg, Manitoba R2P 1W2<br />
                    <b>Phone: </b>+1 (204) 330-8405<br/>
                    <b>Email: </b><a href="mailto:info@navkamaltransport.com" style={{color: '#fff'}}>info@navkamaltransport.com</a><br/><br/>
                </div>
            </div>
            <hr class="smallScreenSeparator" />
            <div class="footerDiv" style={{verticalAlign:'top'}}>
                <a href='https://goo.gl/maps/GZQioHjQD3yegaM98' target='blank'
                    class={'footer-links'}>
                    <img src={LocationIcon} width={12}  />View on map
                </a>
                <Link to="/services" class={'footer-links'}>Services</Link>
                <Link to="/about" class={'footer-links'}>About us</Link>
                <Link to="/contact" class={'footer-links'}>Contact</Link>
                <a class='footer-icon' href='https://www.instagram.com' target='blank'>
                    <img src={InstagramIcon} width={20} style={{verticalAlign:'bottom'}} />
                </a>
                <a class='footer-icon' href='https://www.facebook.com' target='blank'>
                    <img src={FacebookIcon} width={20} style={{verticalAlign:'bottom'}} />
                </a>
                
            </div>
            <hr />
            <div class="footerCopyRight">©2021 NavKamal Transport Ltd.</div>
          </div>
        </footer>
    );
}

export default Footer;