import React from 'react';
import { useEffect } from 'react';
import TruckImage from './truck-image.jfif';
import PhoneIcon from './phone-b.svg';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

function About() {
    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
                <meta name="description" 
                    content={'NavKamal Transport Ltd. is a licensed logistics and freight forwarding company. We have a fleet of well-maintained new and used trucks.'} />
            </Helmet>
            <h1 style={{ margin:0, padding:10}}>About Us</h1>
            <div class="container-div-about">
                <p style={{ marginTop:0}}>NavKamal Transport Ltd. is a licensed logistics and freight forwarding company. We have a fleet of well-maintained new and used trucks. As being a family owned business we take pride in being regarded as one of the most reliable and affordable transportation service providers in the country.</p>
                <div class="containerDiv-services-reefer-small" style={{padding:'2% 0px', width: '100%'}}>
                    <img src={TruckImage} width='100%' />
                </div>
                <div class="containerDiv-bordered" style={{ margin: '0', width:'99.5%'}}>
                    <Link to="/contact" class='home-services-icon' style={{color:'gray', padding:'2% 0px'}}>
                        <h1 style={{ margin: 0, padding: '0 10px 10px 10px', textAlign: 'center', color: 'rgb(24, 144, 255)' }}>Contact</h1>
                        <table style={{ margin: 'auto' }}>
                            <tr>
                                <td style={{ textAlign: "right" }}>Sukhjinder Dhaliwal</td>
                                <td style={{ textAlign: "left" }}><img src={PhoneIcon} width={18} style={{ verticalAlign: 'middle' }} />+1 (204) 330-8405</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><b>Email:</b></td>
                                <td style={{ textAlign: "left" }}>info@navkamaltransport.com</td>
                            </tr>
                        </table>
                    </Link>
                </div>
            </div>
            
            <div class="containerDiv-services-reefer-large" style={{width:'40%'}}>
                <img src={TruckImage} width='100%'/>
            </div>
        </React.Fragment>
    );
}
export default About;