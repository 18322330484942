import React from 'react';
import { useEffect } from 'react';
import PhoneIcon from './phone-b.svg';
import LocationIcon from './map-pin-b.svg';
import {Helmet} from "react-helmet";

function Contact() {
    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us</title>
                <meta name="description" 
                    content={'Contact NavKamal Transport Ltd.'} />
            </Helmet>
            <h1 style={{ margin: 0, padding: 10 }}>Contact</h1>
            <div style={{ margin: '0', }}>
                <table style={{ margin: 'auto' }}>
                    <tr>
                        <td style={{ textAlign: "right" }}>Sukhjinder Dhaliwal</td>
                        <td style={{ textAlign: "left" }}><img src={PhoneIcon} width={18} style={{ verticalAlign: 'middle' }} /><a href="tel:12043308405" style={{ color: '#000', textDecoration: 'none' }}>+1 (204) 330-8405</a></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "right" }}><b>Email:</b></td>
                        <td style={{ textAlign: "left" }}><a href="mailto:info@navkamaltransport.com" style={{ color: '#000', textDecoration: 'none' }}>info@navkamaltransport.com</a></td>
                    </tr>
                </table>

                <h2 style={{ padding: 0, margin: 0 }}>
                    <img src={LocationIcon} width={18} /> Location</h2>
            249 Cartesian Gate<br />
            Winnipeg, Manitoba R2P 1W2<br />
            </div>
            <div style={{ padding: 15 }}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.4074627606483!2d-97.16226348428609!3d49.966216779411475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52ea6e024ebe6a8f%3A0x72b4e39f1cb800ac!2s249%20Cartesian%20Gate%2C%20Winnipeg%2C%20MB%20R2P%202T7!5e0!3m2!1sen!2sca!4v1611560453743!5m2!1sen!2sca"
                    width={'100%'} height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </React.Fragment>
    );
}

export default Contact;