import Home from './home';
import About from './about';
import Contact from './contact';
import Services from './services';
import './App.css';
import NavigationBar from './navigationBar';
import Footer from './footer';
import { Route, Link, useLocation } from 'react-router-dom';
import {useEffect, useState} from 'react';
import TruckImageMain from './main-truck.jpg';

function App() {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState();
  var currentPath = '/' + location.pathname.split('/')[1];
  return (
    <div className="App">
      <NavigationBar currentPath={currentPath} windowWidth={windowWidth} />
      {(currentPath === '/' || currentPath === '/home') &&
        <div>
          <img class="home-truck-image" src={TruckImageMain} width='100%' />
          <div class="centered">
            <p>NavKamal Transport Ltd. is located in Winnipeg, Mb. Family-owned business that strives to be the best as a service provider, employer, and community member.  Investing into reliable equipment to better enhance the safety of our employees and our customers. Our drivers are highly trained and knowledgeable in all aspects of transportation, with a big emphasis on safety and on-time delivery.</p>
          </div>
        </div>}

      {currentPath === '/' || currentPath === '/home' ?
        <div class="container" style={{margin:'0px auto', background:'#f0f2f5'}}>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
        </div>
        :
        <div class="container">
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
        </div>
      }
      
      <Footer />

    </div>
  );
}

export default App;
