import React from 'react';
import { useEffect } from 'react';
import DryvanIcon from './dryvan-trailer.svg';
import ReeferIcon from './reefer-trailer.svg';
import PhoneIcon from './phone-b.svg';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

function Home() {
    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>NavKamal Transport Ltd.</title>
                <meta name="description" 
                    content={'NavKamal Transport Ltd. is located in Winnipeg, Mb. Family-owned business that strives to be the best as a service provider, employer, and community..'} />
            </Helmet>
            <h1 style={{ margin:0, padding:10}}>Our Services</h1>
            <div class="containerDiv-bordered" style={{verticalAlign:'middle'}}>
                <Link to="/services" class='home-services-icon'>
                    <img src={DryvanIcon} height='40px' style={{float:'right'}}/>
                    <h4 style={{margin:0, color:'rgb(24, 144, 255)'}}>Dryvan</h4>
                    <span style={{color:'gray'}}>For all your dry goods.</span>
                </Link>
            </div>

            <div class="containerDiv-bordered" style={{verticalAlign:'middle'}}>
                <Link to="/services" class='home-services-icon'>
                    <img src={ReeferIcon} height='40px' style={{float:'right'}}/>
                    <h4 style={{margin:0, color:'rgb(24, 144, 255)'}}>Reefer</h4>
                    <span style={{color:'gray'}}>For all your frozen goods.</span>
                </Link>
            </div>
            <h1 style={{ margin:0, padding:'10px 10px 0px 10px'}}>About Us</h1>
            <div class="containerDiv">
                <p>NavKamal Transport Ltd. is a licensed logistics and freight forwarding company. We have a fleet of well-maintained new and used trucks. As being a family owned business we take pride in being regarded as one of the most reliable and affordable transportation service providers in the country.</p>
            </div>
            <div class="containerDiv">
                <div class="containerDiv-bordered" style={{ margin: '0', width:'99.5%'}}>
                    <Link to="/contact" class='home-services-icon' style={{color:'gray', padding:'2% 0px'}}>
                        <h1 style={{ margin: 0, padding: '0 10px 10px 10px', textAlign: 'center', color: 'rgb(24, 144, 255)' }}>Contact</h1>
                        <table style={{ margin: 'auto' }}>
                            <tr>
                                <td style={{ textAlign: "right" }}>Sukhjinder Dhaliwal</td>
                                <td style={{ textAlign: "left" }}><img src={PhoneIcon} width={18} style={{ verticalAlign: 'middle' }} />+1 (204) 330-8405</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "right" }}><b>Email:</b></td>
                                <td style={{ textAlign: "left" }}>info@navkamaltransport.com</td>
                            </tr>
                        </table>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Home;