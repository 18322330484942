import React from 'react';
import { useEffect } from 'react';
import DryvanIcon from './dryvan-trailer.svg';
import ReeferIcon from './reefer-trailer.svg';
import {Helmet} from "react-helmet";

function Services() {
    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Services</title>
                <meta name="description" 
                    content={'We have dryvan and reefer trailers to transport your cargo.'} />
            </Helmet>
            <h1 style={{ margin:0, padding:10}}>Services</h1>
            <div class="containerDiv">
                <h3>Dryvan</h3>
                <p>Our dry van services are necessary when the shipment doesn’t require a temperature-controlled environment but needs to be secured and protected.</p>
            </div>
            <div class="containerDiv">
                <img src={DryvanIcon} width='100%'/>
            </div>

            <div class="containerDiv-services-reefer-large">
                <img src={ReeferIcon} width='100%'/>
            </div>
            <div class="containerDiv">
                <h3>Reefer</h3>
                <p>Our fleet of refrigerated trailers can handle your frozen and refrigerated freight at the temperature you need.</p>
            </div>
            <div class="containerDiv-services-reefer-small">
                <img src={ReeferIcon} width='100%'/>
            </div>
            

        </React.Fragment>
    );
}

export default Services;